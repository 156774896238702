import React from 'react'
import { Link, PageProps } from 'gatsby'
import Layout from '@/shared/globalStyle'
import SEO from '@/shared/seo'
import Wrapper from '@/components/atoms/util/Wrapper'
import 'twin.macro'

const Contact: React.FC<PageProps> = ({ path }) => (
  <Layout path={path}>
    <SEO title="送信完了" lang="ja" />
    <section tw="lg:pt-24 pt-14 lg:pb-80 pb-56">
      <Wrapper>
        <h2 tw="font-en lg:text-7xl text-4xl text-center">CONTACT</h2>
        <p tw="lg:mt-6 mt-3.5 lg:text-2xl text-sm text-center">
          送信が完了しました。
        </p>
        <Link to="/" tw="block text-center underline text-xl lg:mt-8 mt-4">
          トップに戻る
        </Link>
      </Wrapper>
    </section>
  </Layout>
)

export default Contact
